<template>
  <div class="p-grid p-justify-center">
    <div class="p-col-12 p-md-8">
      <div class="card">
        <div class="p-d-flex p-ai-center">
          <div class="p-mr-3">Список чеклистов</div>
          <Button icon="pi pi-plus" @click="createChecklist" />
        </div>

        <div class="p-mt-3 p-field">
          <DataTable
            :value="checklistsTemplates"
            class="p-datatable-customers p-datatable-striped"
            dataKey="id"
            :rows="10"
            :rowHover="true"
            :autoLayout="true"
            v-model:selection="selectedChecklist"
            selectionMode="single"
            :loading="isLoading"
          >
            <template #empty>Список чеклистов пуст</template>
            <template #loading>Загрузка...</template>
            <Column field="name" header="Название" :sortable="true">
              <template #body="slotProps"> {{ slotProps.data.name }} </template>
            </Column>
            <Column field="name" header="Тип чеклиста" :sortable="true">
              <template #body="slotProps">
                {{
                  checklistTypes.find((t) => t.value === slotProps?.data?.type)
                    ?.name
                }}
              </template>
            </Column>
            <Column
              field="normalHourPrice"
              header="Стоимость нормо-часа"
              :sortable="true"
            >
              <template #body="slotProps">
                {{ slotProps.data.normalHourPrice / 100 }} руб.
              </template>
            </Column>
            <Column field="delete">
              <template #body="slotProps">
                <Button
                  class="p-button-warning p-mr-3"
                  icon="pi pi-pencil"
                  @click="editChecklist(slotProps.data.id)"
                />
                <Button
                  class="p-button-danger"
                  @click="deleteChecklist(slotProps.data.id)"
                  icon="pi pi-trash"
                />
              </template>
            </Column>
          </DataTable>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { environment } from "@/config";

export default {
  name: "ChecklistMaker",
  data() {
    return {
      checklistsTemplates: [],
      isLoading: false,
      selectedChecklist: null,
    };
  },
  mounted() {
    this.loadTemplates();
  },
  watch: {
    selectedChecklist(newValue) {
      this.editChecklist(newValue.id);
    },
  },
  computed: {
    checklistTypes() {
      return this.$store.getters["data/workTypes"];
    },
  },
  methods: {
    createChecklist() {
      this.$router.push("/create_checklist");
    },
    editChecklist(id) {
      this.$router.push(`/edit_checklist/${id}`);
    },
    async deleteChecklist(id) {
      this.isLoading = true;

      try {
        await fetch(`${environment.apiUrl}/checklist_templates/${id}`, {
          headers: {
            Authorization: `Bearer ${this.$store.getters.token}`,
          },
          method: "DELETE",
        });
        await this.loadTemplates();
      } catch (err) {
        console.log(err);
      }

      this.isLoading = false;
    },
    async loadTemplates() {
      this.isLoading = true;

      try {
        const response = await fetch(
          `${environment.apiUrl}/checklist_templates`,
          {
            headers: {
              Authorization: `Bearer ${this.$store.getters.token}`,
              "Content-Type": "application/json",
            },
          }
        );
        this.checklistsTemplates = await response.json();
      } catch (err) {
        console.log(err);
      }

      this.isLoading = false;
    },
  },
};
</script>

<style scoped></style>
